//images
import TopBackground from "../images/portoHeader.jpg";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("norte-2020.topTitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: true,
      full: true,
      image: {
        smallHeight: true,
        src: TopBackground,
        alt: t("norte-2020.imgAlt"),
        bottomPosition: true,
      },
    },
  };
};
