import * as React from 'react'
import { withTrans } from '../i18n/withTrans'

import { pageTexts } from '../content/norte2020'

import portoPdf from '../files/porto2020.pdf'
import fichaProjetoPdf from '../files/ficha-de-projeto.pdf'

// components
import Layout from '../components/layout'
import TextImgBackgroundFull from '../components/textImgBackgroundFull'

class norte extends React.Component {
  constructor(props) {
    super(props)
    this.t = props.t
    this.content = pageTexts(this.t)
  }

  render() {
    return (
      <Layout pageName='norte-2020' whiteMenu={false}>
        <TextImgBackgroundFull info={this.content.topInfo}></TextImgBackgroundFull>
        <div className='d-flex justify-content-center pb-20'>
          <embed src={portoPdf} width='800px' height='800px' />
        </div>
        <div className='d-flex justify-content-center pb-20'>
          <embed src={fichaProjetoPdf} width='800px' height='800px' />
        </div>
      </Layout>
    )
  }
}

export default withTrans(norte)
